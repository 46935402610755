/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/pagination';

// Full Calendar
@import "./assets/fullcalendar/fullcalendar.scss";


@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    background: white !important;
}

ion-menu {
    max-width: 280px !important;
}

button {
    border-top-style: inherit !important;
    border-right-style: inherit !important;
    border-bottom-style: inherit !important;
    border-left-style: inherit !important;
}

// div {
//     overflow-wrap: anywhere;
// }

.div-safe-space {
    padding-top: var(--ion-safe-area-top) !important;
    padding-bottom: var(--ion-safe-area-bottom) !important;
}

.div-safe-space-top {
    padding-top: var(--ion-safe-area-top) !important;
}

.div-safe-space-top-chat-header {
    padding-top: var(--ion-safe-area-top) !important;
    padding-bottom: 0.5rem !important;
}

.fix-for-safari {
    -webkit-user-select: text !important;
    user-select: text !important;
    direction: ltr !important;
}


.div-safe-space-bottom {
    padding-bottom: var(--ion-safe-area-bottom) !important;
}

ion-content {
    --background: var(--ion-color-background-content) !important;
    --padding-top: var(--ion-safe-area-top) !important;
    --padding-bottom: var(--ion-safe-area-bottom) !important;
}

ion-content.content-with-white-background {
    --background: white !important;
}

.content-without-padding {
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
}

ion-modal.bottom-modal {
    --height: 30rem;
    --border-radius: 10px 10px 0px 0px;
    align-items: flex-end;
}

ion-modal.video-preview {
    --height: 100%;
    --border-radius: 0px 0px 0px 0px;
    --backdrop-opacity: 0.9;
}

ion-modal.photo-preview {
    --height: 100%;
    --width: 100%;
    --background: transparent;
    --border-radius: 0px 0px 0px 0px;
    --backdrop-opacity: 0.9;
}

ion-modal.folder-bottom-modal {
    --height: 30rem;
    --border-radius: 10px 10px 0px 0px;
    align-items: flex-end;
}

ion-modal.privacy-policy-and-term-conditions-modal {
    --height: 100%;
    --width: 100%;
}

ion-popover.notificationsPopover {
    --width: 300px;
}

.datatable-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
    background-color: #faceaf !important;
  }

  .ngx-datatable.material .datatable-body .progress-linear .container .bar{
    background-color: #e66b10;
  }

ion-segment.colored {
    ion-segment-button {
        --indicator-color: var(--ion-color-primary-text);
        --color-checked: var(--ion-color-primary);
        --color: var(--ion-color-primary-text);
    }
}

ion-segment.uncolored {
    ion-segment-button {
        --indicator-color: var(--ion-color-primary);
        --color-checked: var(--ion-color-primary-text);
        --color: var(--ion-color-primary);
    }
}

ion-modal.forgot-modal {
    --height: 22rem;
    --border-radius: 10px 10px 0px 0px;
    align-items: flex-end;
}

ion-modal.recommendation-modal {
    --height: 15rem;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.stack-modals {
    --height: 34rem;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.upload-modal {
    --height: 65%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.stack-modal {
    --height: 24rem;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.stack-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

.Coppa_dialog {
    .alert-wrapper {
      max-width: 500px;
    }
    .alert-head{
      overflow: auto;  
    }
  }
  
.map-container {
    width: 20rem !important;

    @media (min-width: 640px) {
        width: 24.9rem !important;
    }

    height: 15.9rem !important;
}

@layer base {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

video {
    width: 100%;
}

.inside-croper-img img {
    display: inline-block;
}

.ck-editor__editable {
    min-height: 200px;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.ck.ck-editor__editable_inline {
    border: 1px solid rgb(209 213 219) !important;
    overflow: auto;
    color: rgb(55 65 81) !important;
    padding: 0 var(--ck-spacing-standard);
}

.fc-daygrid-day-top {
    cursor: pointer !important;
}


.post_editor {
    .ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
        border: 0 !important;
        background: white !important;
    }
    .ck.ck-toolbar {
        border: 0 !important;
        background: none !important;
    }

    .ck-editor__editable {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    &.content {
        .ck-editor__editable {
            min-height: 0 !important;
        }
    }
}

ion-popover {
    &.support_bubble {
        &::part(content) {
            min-width: 300px !important;
        }
    }
}
ion-modal {
    // @screen  {
        &.aciModel {
            --width: auto !important;
            --height: auto !important;
            padding: 10px;
        }
    // }
}

ion-slides {
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: #999999; // Set your desired background color
      opacity: 1;
    }
  
    .swiper-pagination-bullet-active {
        width: 15px;
        height: 15px;
      background-color: #d4630f; // Set your desired active bullet color
    }
  }

ion-modal {
    // @screen md {
        &.sliderModel {
            --width: 380px !important;
            --height: 100% !important;
            --backdrop-opacity: 0.7;
            // --background: white !important;
        }
    // }
}

ion-modal {
    &.fullscreen_modal {
        --height: 100%;
        --width: 100%;
    }
}