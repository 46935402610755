/* ----------------------------------------------------------------------------------------------------- */
/*  @ FullCalendar overrides

/* ----------------------------------------------------------------------------------------------------- */

full-calendar {

  .fc-media-screen {
    min-height: 100%;
    width: 100%;
  }

  .fc-day-today {
    background: none !important;
    .fc-daygrid-day-number {
      border-radius: 100%;
      background-color: var(--ion-color-primary) !important;
      color: var(--ion-color-primary-text) !important;
    }
  }

  .fc-theme-standard th {
    border-color: rgb(226, 232, 240) !important;
  }

  .fc-theme-standard td {
    border-color: rgb(226, 232, 240) !important;
  }

  .fc-scrollgrid {
    border: 0px;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0 !important;
  }
  
  .fc-scrollgrid table {
    border-left-style: hidden;
    border-right-style: hidden;
    border-top-style: hidden;
  }

  .fc-event {
    border: 0px;
    padding: 2px;
    font-size: 12px;
    margin: 0px 6px 4px !important;
  }

  .fc-scrollgrid-section-header>* {
    border-bottom-width: 0;
  }

  .fc-scrollgrid-section>* {
    border-left-width: 0;
    border-top-width: 0;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid rgb(226, 232, 240);
  }

  .fc-col-header-cell {
    border-width: 0px 1px;
    padding: 8px 0px 0px;
  }

  .fc-daygrid-day-top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  .fc-col-header-cell .fc-col-header-cell-cushion {
    color: rgb(107, 114, 128);
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px 4px;
  }

  .fc-daygrid-day-top .fc-daygrid-day-number {
    color: black;
    font-weight: bold;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin: 4px 0px;
    border-radius: 50%;
    float: none;
    line-height: 1;
  }
}
